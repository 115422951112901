var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"card mb-1",attrs:{"title":"Data Alumni"}},[_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"lg":"12"}},[_c('label',{staticClass:"d-block"},[_vm._v("Tahun Lulus")]),_c('b-form-input',{attrs:{"placeholder":"Masukkan Tahun Lulus"},model:{value:(_vm.graduationYear),callback:function ($$v) {_vm.graduationYear=$$v},expression:"graduationYear"}})],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"lg":"12"}},[_c('label',{staticClass:"d-block"},[_vm._v("Tahun Ajaran")]),_c('b-form-select',{staticClass:"w-100 mr-1",attrs:{"options":_vm.yearsList.map(function (item) { return ({
              value: item.id,
              text: item.name,
            }); })},on:{"change":function($event){return _vm.refreshTableData()}},model:{value:(_vm.selectedYear),callback:function ($$v) {_vm.selectedYear=$$v},expression:"selectedYear"}})],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"lg":"12"}},[_c('label',{staticClass:"d-block"},[_vm._v("Kelas")]),_c('b-form-select',{staticClass:"w-100 mr-1",attrs:{"options":_vm.classroomList.map(function (item) { return ({
              value: item.id,
              text: item.name,
            }); }),"disabled":_vm.isSelectDisabled},on:{"change":function($event){return _vm.refreshTableData()}},model:{value:(_vm.selectedClassroom),callback:function ($$v) {_vm.selectedClassroom=$$v},expression:"selectedClassroom"}})],1)],1),_c('div',{staticClass:"mt-2"},[_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"lg":"12"}},[_c('label',{staticClass:"d-block"},[_vm._v("Pilih Siswa")]),_c('div',[_c('b-row',[_c('b-col',{attrs:{"lg":"1"}},[_c('b-form-select',{staticClass:"w-auto mr-1",attrs:{"options":_vm.options},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}})],1),_c('b-col'),_c('b-col',[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"mr-1"},[_vm._v("Filter")]),_c('b-form-input',{staticClass:"d-inline-block mr-1 search-min-width",attrs:{"placeholder":"Search","type":"text"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)])],1)],1),(_vm.isFilterFilled)?_c('vue-good-table',{ref:"student-table",attrs:{"columns":_vm.columns,"rows":_vm.rows,"search-options":{
                enabled: true,
                externalQuery: _vm.searchTerm,
              },"select-options":{
                enabled: true,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              },"pagination-options":{
                enabled: true,
                perPage: _vm.pageLength,
              },"sort-options":{
                enabled: true,
                initialSortBy: { field: 'fullname', type: 'asc' },
              },"line-numbers":true},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'fullname')?_c('span',{staticClass:"text-nowrap"},[_c('b-avatar',{staticClass:"mx-1",attrs:{"variant":"light-primary","src":props.row.avatar}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.fullname))])],1):(props.column.field === 'classroom')?_c('span',{staticClass:"d-flex justify-content-start"},[(props.row.classroom.length > 0)?_c('div',_vm._l((props.row.classroom),function(classroomItem){return _c('b-badge',{key:classroomItem.id,staticClass:"small-gap-right text-white",attrs:{"variant":"success"}},[_vm._v(" "+_vm._s(classroomItem.classroom.name)+" ")])}),1):_vm._e(),(props.row.classroom.length === 0)?_c('div',[_c('small',[_vm._v("Belum Punya Kelas")])]):_vm._e()]):(props.column.field === 'action')?_c('span',{staticClass:"d-flex justify-content-start"}):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('b-form-select',{staticClass:"w-auto mr-1",attrs:{"hidden":"","options":_vm.options},on:{"input":function($event){return props.perPageChanged({ currentPerPage: _vm.pageLength })}},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap "},[_vm._v(" Showing "+_vm._s(_vm.pageLength <= _vm.totalData ? _vm.pageLength * _vm.currentPage : _vm.totalData)+" of "+_vm._s(_vm.totalData)+" entries ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":_vm.currentPage,"total-rows":_vm.totalData,"per-page":_vm.pageLength,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) {
                          _vm.currentPage = value
                          props.pageChanged({ currentPage: value })
                        }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}],null,false,3983401046)}):_vm._e()],1)])],1),(!_vm.isFilterFilled)?_c('div',{staticClass:"d-flex align-items-center flex-column"},[_c('b-img',{attrs:{"width":"300px","src":_vm.emptyStateImg}}),_c('span',{staticClass:"text-xl"},[_vm._v(" Data Siswa Akan Muncul Setelah Memilih Tahun Ajaran dan Kelas ")])],1):_vm._e(),_c('div',[_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary"},on:{"click":_vm.submitForm}},[_c('div',{staticClass:"d-flex align-items-center "},[_c('b-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}],staticClass:"mr-1",attrs:{"variant":"light","small":""}}),_vm._v(" Simpan ")],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }