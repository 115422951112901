<template>
  <div>
    <!-- Task Card Header -->
    <b-card class="card mb-1" title="Data Alumni">
      <b-row>
        <b-col lg="12" class="mb-1">
          <label class="d-block">Tahun Lulus</label>
          <b-form-input
            v-model="graduationYear"
            placeholder="Masukkan Tahun Lulus"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="12" class="mb-1">
          <label class="d-block">Tahun Ajaran</label>

          <!-- School Years -->
          <b-form-select
            v-model="selectedYear"
            class="w-100 mr-1"
            :options="
              yearsList.map((item) => ({
                value: item.id,
                text: item.name,
              }))
            "
            @change="refreshTableData()"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12" class="mb-1">
          <label class="d-block">Kelas</label>

          <!-- Classroom -->
          <b-form-select
            v-model="selectedClassroom"
            class="w-100 mr-1"
            :options="
              classroomList.map((item) => ({
                value: item.id,
                text: item.name,
              }))
            "
            :disabled="isSelectDisabled"
            @change="refreshTableData()"
          />
        </b-col>
      </b-row>

      <div class="mt-2">
        <b-row>
          <b-col lg="12" class="mb-1">
            <!-- Table of Student-->
            <label class="d-block">Pilih Siswa</label>
            <div>
              <b-row>
                <b-col lg="1">
                  <b-form-select
                    v-model="pageLength"
                    class="w-auto mr-1"
                    :options="options"
                  />
                </b-col>
                <b-col />
                <b-col>
                  <b-form-group>
                    <div class="d-flex align-items-center">
                      <label class="mr-1">Filter</label>
                      <b-form-input
                        v-model="searchTerm"
                        placeholder="Search"
                        type="text"
                        class="d-inline-block mr-1 search-min-width"
                      />
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- table -->
              <vue-good-table
                v-if="isFilterFilled"
                ref="student-table"
                :columns="columns"
                :rows="rows"
                :search-options="{
                  enabled: true,
                  externalQuery: searchTerm,
                }"
                :select-options="{
                  enabled: true,
                  selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                  selectionInfoClass: 'custom-class',
                  selectionText: 'rows selected',
                  clearSelectionText: 'clear',
                  disableSelectInfo: true, // disable the select info panel on top
                  selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                }"
                :pagination-options="{
                  enabled: true,
                  perPage: pageLength,
                }"
                :sort-options="{
                  enabled: true,
                  initialSortBy: { field: 'fullname', type: 'asc' },
                }"
                :line-numbers="true"
              >
                <template slot="table-row" slot-scope="props">
                  <!-- Column: Name -->
                  <span
                    v-if="props.column.field === 'fullname'"
                    class="text-nowrap"
                  >
                    <b-avatar
                      variant="light-primary"
                      :src="props.row.avatar"
                      class="mx-1"
                    />
                    <span class="text-nowrap">{{ props.row.fullname }}</span>
                  </span>

                  <!-- Column: Status -->
                  <span
                    v-else-if="props.column.field === 'classroom'"
                    class="d-flex justify-content-start"
                  >
                    <div v-if="props.row.classroom.length > 0">
                      <b-badge
                        v-for="classroomItem in props.row.classroom"
                        :key="classroomItem.id"
                        variant="success"
                        class="small-gap-right text-white"
                      >
                        {{ classroomItem.classroom.name }}
                      </b-badge>
                    </div>
                    <div v-if="props.row.classroom.length === 0">
                      <small>Belum Punya Kelas</small>
                    </div>
                  </span>

                  <!-- Column: Action -->
                  <span
                    v-else-if="props.column.field === 'action'"
                    class="d-flex justify-content-start"
                  >
                    <!-- <b-button
                      variant="warning"
                      size="sm"
                      class="mr-1"
                      :to="{
                        name: checkAuthorizeRole('alumni-edit'),
                        params: { id: props.row.id },
                      }"
                    >
                      <i class="ti ti-pencil" />
                    </b-button>
                    <b-button
                      variant="danger"
                      size="sm"
                      class="mr-1"
                      @click="handleDelete(props.row)"
                    >
                      <i class="ti ti-trash" />
                    </b-button> -->
                  </span>

                  <!-- Column: Common -->
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>

                <!-- pagination -->
                <template slot="pagination-bottom" slot-scope="props">
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <b-form-select
                        v-model="pageLength"
                        hidden
                        class="w-auto mr-1"
                        :options="options"
                        @input="
                          props.perPageChanged({ currentPerPage: pageLength })
                        "
                      />
                      <span class="text-nowrap ">
                        Showing
                        {{
                          pageLength <= totalData
                            ? pageLength * currentPage
                            : totalData
                        }}
                        of {{ totalData }} entries
                      </span>
                    </div>
                    <div>
                      <b-pagination
                        :value="currentPage"
                        :total-rows="totalData"
                        :per-page="pageLength"
                        first-number
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mt-1 mb-0"
                        @input="
                          (value) => {
                            currentPage = value
                            props.pageChanged({ currentPage: value })
                          }
                        "
                      >
                        <template #prev-text>
                          <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                          <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                      </b-pagination>
                    </div>
                  </div>
                </template>
              </vue-good-table>
            </div>
          </b-col>
        </b-row>
        <!-- no data -->
        <div
          v-if="!isFilterFilled"
          class="d-flex align-items-center flex-column"
        >
          <b-img width="300px" :src="emptyStateImg" />
          <span class="text-xl">
            Data Siswa Akan Muncul Setelah Memilih Tahun Ajaran dan Kelas
          </span>
        </div>
        <div>
          <b-button variant="primary" class="float-right" @click="submitForm">
            <div class="d-flex align-items-center ">
              <b-spinner
                v-show="isLoading"
                class="mr-1"
                variant="light"
                small
              />
              Simpan
            </div>
          </b-button>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import client from '@/libs/http/axios-config'
import { checkAuthorizeRole } from '@/auth/utils'

import {
  BRow,
  BCol,
  BCard,
  BButton,
  BCardText,
  BImg,
  BAvatar,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BFormInvalidFeedback,
  BPagination,
  BSpinner,
  BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { VueGoodTable } from 'vue-good-table'

// eslint-disable-next-line import/no-cycle
import { avatarText, pascalCase } from '@core/utils/filter'

export default {
  components: {
    BFormInvalidFeedback,
    BRow,
    BCol,
    BCard,
    BButton,
    BCardText,
    BImg,
    BAvatar,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BSpinner,
    BPagination,
    BBadge,

    VueGoodTable,
  },
  data() {
    return {
      emptyStateImg: require('@/assets/images/illustration/empty-alumni-state.svg'),

      isLoading: false,
      buttonLoadingId: null,
      isSelectDisabled: true,

      // Filter
      selectedClassroom: 'Pilih Tahun Ajaran Terlebih Dulu',
      selectedYear: 'Pilih Tahun Ajaran',
      classroomList: [
        {
          id: 'Pilih Tahun Ajaran Terlebih Dulu',
          name: 'Pilih Tahun Ajaran Terlebih Dulu',
        },
      ],
      yearsList: [],

      // Table option
      searchTerm: '',
      currentPage: 1,
      pageLength: 10,
      totalData: 0,
      options: ['10', '20', '30'],
      columns: [
        {
          label: 'Nama Siswa',
          field: 'fullname',
        },
        {
          label: 'Email',
          field: 'email',
        },
        {
          label: 'Telepon',
          field: 'phone',
        },
      ],
      rows: [],
      graduationYear: '',
    }
  },
  computed: {
    isFilterFilled() {
      return (
        this.selectedYear !== 'Pilih Tahun Ajaran' &&
        this.selectedClassroom !== 'Pilih Kelas'
      )
    },

    currentYear() {
      const today = new Date()
      const currentYear = today.getFullYear()

      return currentYear
    },
  },

  watch: {
    selectedYear() {
      if (this.selectedYear === 'Semua Tahun Ajaran') {
        this.selectedClassroom = 'Semua Kelas'
        this.isSelectDisabled = true
      }

      this.isSelectDisabled = false
      this.refreshClassroomList()
    },
    currentPage() {
      this.refreshTableData()
    },
    searchTerm() {
      this.refreshTableData()
    },
  },
  async mounted() {
    await client.get('utils/school-years?per_page=9999').then((response) => {
      const years = response.data.data

      const listOfYears = years.map((year) => ({
        id: year.id,
        name: `${year.period} (${year.name})`,
      }))

      listOfYears.unshift({
        id: 'Pilih Tahun Ajaran',
        name: 'Pilih Tahun Ajaran',
      })

      this.yearsList = listOfYears
    })

    this.graduationYear = this.currentYear
  },
  methods: {
    avatarText,
    onFileChange(e) {
      const file = e.target.files[0]
      this.formData.classroomLogo = file
      this.blobImage = URL.createObjectURL(file)
    },
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },
    validateForm() {
      let isValid = true
      if (this.formData.classroomName === '') {
        this.formErrors.classroomName = {
          state: false,
          message: 'Nama Kelas tidak boleh kosong',
        }
        isValid = false
      } else {
        this.formErrors.classroomName = {
          state: null,
          message: '',
        }
      }

      return isValid
    },
    async submitForm() {
      // start loading
      this.isLoading = true

      const schoolId = JSON.parse(localStorage.getItem('userData')).school_id
      const selectedStudents = this.$refs['student-table'].selectedRows

      const data = {
        data: selectedStudents.map((student) => ({
          user_id: student.id,
          school_id: schoolId,
          batch: this.graduationYear,
        })),
      }

      console.log(data)

      // create classroom
      await client
        .post(`/school-admin/${schoolId}/graduations`, data)
        .then((response) => {
          // stop loading
          this.isLoading = false

          // show success toast
          this.showToast(
            'success',
            'CheckIcon',
            'Berhasil',
            'Data Alumni Berhasil Disimpan'
          )

          // redirect to classroom detail
          this.$router.push({
            name: checkAuthorizeRole('alumni'),
          })
        })
        .catch((error) => {
          console.error(error)

          // stop loading
          this.isLoading = false

          // show error toast
          this.showToast('error', 'XIcon', 'Gagal', error.response.data.message)
        })
    },

    async refreshClassroomList() {
      await client
        .get(
          `school-admin/classrooms?per_page=9999&period=${this.selectedYear}`
        )
        .then((response) => {
          const classrooms = response.data.data.data

          const listOfClassrooms = classrooms.map((classroom) => ({
            id: classroom.id,
            name: classroom.name,
          }))

          if (classrooms.length !== 0) {
            this.selectedClassroom = 'Pilih Kelas'
            listOfClassrooms.unshift({ id: 'Pilih Kelas', name: 'Pilih Kelas' })
          } else {
            this.selectedClassroom = 'Tidak Ada Kelas'
            listOfClassrooms.unshift({
              id: 'Tidak Ada Kelas',
              name: 'Tidak Ada Kelas',
            })
          }

          this.classroomList = listOfClassrooms
        })
    },

    async refreshTableData() {
      // this.isLoading = true

      const filterbyClassroom =
        this.selectedClassroom === 'Semua Kelas' ? '' : this.selectedClassroom

      await client
        .get(
          `/school-admin/users?per_page=${this.pageLength}&role=Student&status=Active&classroom_id=${filterbyClassroom}&page=${this.currentPage}&search=${this.searchTerm}`
        )
        .then((response) => {
          const students = response.data.data.data
          this.totalData = response.data.data.total

          const listOfStudents = students.map((student) => ({
            id: student.id,
            fullname: pascalCase(student.name),
            avatar: student.avatar,
            phone: student.phone,
            email: student.email,
          }))

          this.rows = listOfStudents
          // this.isLoading = false
        })
        .catch((error) => {
          console.log(error)

          this.showToast(
            'error',
            'XIcon',
            'Gagal',
            'Data Gagal Dimuat, Silahkan Coba Lagi'
          )
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '~@core/scss/vue/libs/vue-good-table.scss';

.line-height-small {
  line-height: 1.5rem;
}

.regular-icon {
  top: 0 !important;
  font-size: 1.2rem;
}

.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.card-icon-lg {
  top: 0 !important;
  font-size: 2.5rem;
}

.pointer {
  cursor: pointer;
}

.is-invalid {
  border-color: #ea5455 !important;
}

.w-30 {
  width: 30% !important;
}

.w-70 {
  width: 70% !important;
}
</style>
